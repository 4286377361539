<template>
  <div class="m1">
    <div class="hd ">
      <div class="h1">
        <el-breadcrumb separator="/" style="margin-top: 1.5rem;">
          <el-breadcrumb-item
            ><span style="color: rgba(43, 222, 201, 0.4);">施工管理</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            ><span style="color: rgba(43, 222, 201, .4);"
              >施工信息</span
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><span style="color: rgba(43, 222, 201, 1);"
              >安装过程</span
            ></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div
        class="h2"
        style="right: 15rem; background-color: #00FFFF;color: #00131E;"
        @click="Todit()"
      >
        位置信息
      </div>
      <div class="h2" @click="back()">
        返回
      </div>
    </div>
    <div class="hd1">
      设备基本信息
      <div class="h1" @click="Xbxx(Data.id)">
        <img style="width: 3rem; height: 3rem;" src="../../static/xg.png" />
      </div>
      <a
        v-if="isCreated"
        :href="reportUrl"
        target="view_window"
        class="h2"
        style="cursor: pointer;"
      >
        查看报告
      </a>
      <el-button
        class="create-report-btn"
        @click="Scbg1()"
        :loading="reportLoading"
        >生成报告</el-button
      >
      <!-- <div v-if="isCreated"  class="h2"  @click="downloadFile()" style="color: white;">
        下载报告
      </div> -->
    </div>
    <div class="hd2">
      <span class="h1"> 设备名称：{{ Data.name }} </span>
      <span class="h1"> 规格型号：{{ Data.specifications }} </span>
      <span class="h1"> 设备编号：{{ Data.serialNumber }} </span>
      <span class="h1"> 生产厂家：{{ Data.manufactor }} </span>

      <!-- 			<span class="h1">
				电压等级：{{Data.voltageLevel}}
			</span> -->
      <span class="h1"> 安装位置：{{ Data.address }} </span>
      <span class="h1"> 生产日期：{{ Data.productionData }} </span>
      <span class="h1"> 安装状态：{{ state[list.state] }} </span>
      <span class="h1" v-for="(item, index) in newA1" :key="index">
        {{ item }}
      </span>
    </div>
    <div class="hd1">
      安装信息
      <div class="h1" style="right: 3rem;" @click="Xbxx1(list.id)">
        <img style="width: 3rem; height: 3rem;" src="../../static/xg.png" />
      </div>
    </div>
    <div class="hd2">
      <span class="h1"> 安装项目名称：{{ list.projectName }} </span>
      <span class="h1"> 制作单位：{{ list.produceCompany }} </span>
      <span class="h1"> 安装位置：{{ list.installAddress }} </span>
      <span class="h1"> 开工时间：{{ list.constructionDate }} </span>
      <span class="h1"> 竣工时间：{{ list.endTime }} </span>
      <span class="h1"> 现场负责人：{{ list.constructionDirector }} </span>
      <span class="h1"> 施工作业人员：{{ list.employeeName }} </span>
      <span class="h1" v-for="(item, index) in newA" :key="index">
        {{ item.name }}: {{ item.value }}
      </span>
    </div>

    <div class="hd1">
      地图截图
    </div>

    <div style="display: flex;align-items: flex-end;">
      <el-upload
        class="upload-demo"
        style=""
        action="https://jsonplaceholder.typicode.com"
        :show-file-list="false"
        :limit="1"
        :auto-upload="false"
        :on-change="changeMapImg"
      >
        <div class="map-img-box">
          <img
            v-if="null !== list.mapImg"
            style="width: 15rem;height: 10rem;"
            :src="list.mapImg"
          />

          <img
            v-if="null == list.mapImg"
            style="width: 10rem;height: 10rem;"
            src="../../static/add.png"
          />
        </div>
      </el-upload>

      <div
        style="font-size:2rem;margin-left: 1rem;"
        v-if="null !== list.mapImg"
        @click="showImgViewer(list.mapImg)"
      >
        查看大图
      </div>
    </div>

    <div>
      <div class="hd1" style="display: inline-block;">
        施工步骤
        <div class="h1" style="right: 3rem;">
          <img
            v-if="XG"
            @click="xiug"
            style="width: 3rem; height: 3rem;"
            src="../../static/xg.png"
          />
          <div
            v-if="!XG"
            @click="submitUpload"
            style="width: 6rem; height: 100%; background-color: rgba(43, 201, 222, 1); text-align: center; line-height: 3rem; color: #FFFFFF; border-radius: .1rem;"
          >
            保存
          </div>
        </div>
      </div>
      <div style="display: inline-block; width: 15rem; margin-left: 1rem;">
        <el-steps space="20rem" direction="vertical" :active="Bnb.length">
          <el-step
            v-for="(item, index) in Bnb.length"
            :key="index"
            :title="Index(index)"
          ></el-step>
        </el-steps>
      </div>
      <div ref="element" style="float: right; width: 88%;">
        <div v-for="(item, index) in Bnb" :key="index" style="height: 20rem;">
          <div
            style="display: inline-block; width: 100%; position: relative; height: 3rem;line-height: 3rem;"
          >
            <input
              :disabled="XG"
              style="color: #FFFFFF;"
              type=""
              v-model="item.step"
              name=""
              id=""
              value=""
            />
            <div
              style="position: absolute; top: 50%; right: 2rem; transform: translateY(-50%);"
            >
              {{ item.gmtCreate }}
              <!-- <textarea style="width: 100%;" rows="" cols="" v-model="item.gmtCreate"> </textarea> -->
            </div>
          </div>
          <div
            style="position: relative; margin-top: .5rem; margin-bottom: .5rem;"
          >
            <div
              style="position: absolute; top: 0; width: 20rem; height: 7.5rem; border: 1px solid #00FFFF; overflow: hidden; font-size: 1.4rem; box-sizing: border-box;"
            >
              <!-- {{item.operationProcess}} -->
              <textarea
                :disabled="XG"
                style="outline: none; width: 19rem; height: 7.5rem; background-color: unset; color: #FFFFFF;"
                v-model="item.operationProcess"
              >
              </textarea>
            </div>
            <!-- <div style="display: inline-block; width: 20rem;"></div> -->
            <div
              style="display: inline-block; width: 110rem; margin-left: 20rem;"
            >
              <span
                class="img_1"
                v-for="(item1, index1) in item.imageList"
                :key="index1"
              >
                <div
                  v-if="!XG"
                  @click.stop="dell(index, index1)"
                  style="width: 3rem; height: 3rem; border-radius: 3rem; text-align: center; font-size: 2rem; background-color: crimson; position: absolute;top: -2rem;right: -2rem;"
                >
                  x
                </div>
                <img
                  style="width: 12.5rem; height: 7.5rem;cursor: pointer;
                transition: all 0.6s;"
                  :src="item1.link"
                  @click="showImgViewer(item1.link)"
                />
              </span>
              <span v-if="!XG">
                <el-upload
                  class="upload-demo"
                  style="display: inline-block; margin-left: 2rem;  width: 12.5rem; height: 7.5rem; background-color: #2BDEC9; box-sizing: border-box; overflow: hidden; text-align: center;"
                  ref="upload"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :limit="3"
                  :auto-upload="false"
                  :on-change="onchange"
                  :file-list="fileList"
                >
                  <img
                    style="width: 5rem; height: 5rem; margin-top: 50%;transform: translateY(-25%);"
                    src="../../static/add.png"
                    @click="Progress(index)"
                  />
                </el-upload>
              </span>
              <!-- <span v-if="!XG"
                style="display: inline-block; margin-left: 2rem;  width: 12.5rem; height: 7.5rem; background-color: #2BDEC9; box-sizing: border-box; overflow: hidden; text-align: center;"
                @click="Kszp(item.id)">
                <img style="width: 5rem; height: 5rem; margin-top: 50%;transform: translateY(-100%);"
                  src="../../static/add.png">
              </span> -->
            </div>
          </div>
        </div>
        <!-- <div style="width: 100%; position: relative; height: 3rem;line-height: 3rem;">
          现场环境
          <div style="position: absolute; top: 50%; right: 2rem; transform: translateY(-50%);">
            2021-09-009
          </div>
        </div> -->
      </div>
    </div>

    <el-image-viewer
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :url-list="imgList"
    />

    <!-- 新增弹窗 -->
    <el-dialog title="添加图片" :visible.sync="dialogFormVisible">
      <el-upload
        action="http://monitor.api.cqxddwl.com/upload/image"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :limit="3"
        :auto-upload="false"
        :on-change="onchange"
        :file-list="fileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <el-form :model="form" style="margin-top: 1rem;">
        <el-form-item label="步骤名称:" :label-width="formLabelWidth">
          <el-input v-model="form.step" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="操作过程:" :label-width="formLabelWidth">
          <el-input
            v-model="form.operationProcess"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qux">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑弹窗 设备信息 -->
    <el-dialog title="修改步骤" :visible.sync="dialogFormVisible1">
      <el-form :model="form1">
        <el-form-item label="设备位置:" :label-width="formLabelWidth">
          <el-input v-model="form1.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生产厂家:" :label-width="formLabelWidth">
          <el-input v-model="form1.manufactor" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备名字:" :label-width="formLabelWidth">
          <el-input v-model="form1.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生产日期:" :label-width="formLabelWidth">
          <el-input
            v-model="form1.productionData"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编号:" :label-width="formLabelWidth">
          <el-input
            v-model="form1.serialNumber"
            autocomplete="off"
            disabled="true"
          ></el-input>
        </el-form-item>
        <!-- 				<el-form-item label="设备型号:" :label-width="formLabelWidth">
					<el-input v-model="form1.specifications" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="电压等级:" :label-width="formLabelWidth">
					<el-input v-model="form1.voltageLevel" autocomplete="off"></el-input>
				</el-form-item> -->
        <el-form-item
          style="display: inline-block;"
          v-for="(item, index) in newA1"
          :label="item"
          :label-width="formLabelWidth"
        >
          <!-- <el-input style="width: 12rem;" v-model="item.value" autocomplete="off"></el-input> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qux1">取 消</el-button>
        <el-button type="primary" @click="Form()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑弹窗 设备信息 -->
    <el-dialog title="修改步骤" :visible.sync="dialogFormVisible2">
      <el-form :model="form2">
        <el-form-item label="项目名称:" :label-width="formLabelWidth">
          <el-input v-model="form2.projectName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="制作单位:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.produceCompany"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="安装位置:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.installAddress"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="施工位置:" :label-width="formLabelWidth">
					<el-input v-model="form2.constructionAddress" autocomplete="off"></el-input>
				</el-form-item> -->
        <el-form-item label="开工时间:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.constructionDate"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="竣工时间:" :label-width="formLabelWidth">
          <el-input v-model="form2.endTime" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="施工负责人:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.constructionDirector"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="display: inline-block;"
          v-for="(item, index) in newA"
          :label="item.name"
          label-width="15rem"
          width="1rem"
        >
          <el-input
            style="width: 12rem;"
            v-model="item.value"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qux2">取 消</el-button>
        <el-button type="primary" @click="Form1()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "gsgly",
  data() {
    return {
      reportLoading: false,
      isCreated: false,
      XG: true,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      state: ["安装中", "已完成", "异常"],
      list: [],
      // token: {
      //   token: ''
      // },
      // Hd: 'http://monitor.api.cqxddwl.com',
      // action: 'http://monitor.api.cqxddwl.com/upload/image',
      value: true,
      currentPage4: 1,
      Data: {},
      newA: [],
      newA1: [],
      Bnb: [],
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      form: {
        id: null,
        image: "",
        operationProcess: "",
        step: ""
      },
      form1: {
        address: "重庆",
        id: 0,
        manufactor: "XX厂家",
        name: "设备",
        parameter: "",
        productionData: "",
        serialNumber: "1541245412458",
        specifications: "NDF12451",
        voltageLevel: "12V"
      },
      form2: {
        constructionAddress: "重庆市沙坪坝",
        constructionDate: "2021-10-14",
        endTime: "2021-10-14",
        constructionDirector: "张三",
        id: 1,
        installAddress: "重庆市沙坪坝",
        installInfo: "[object Object]",
        projectName: "安装环网柜",
        produceCompany: "大业"
      },
      formLabelWidth: "15rem",
      Tplist: [],
      reportUrl: "",
      progress: null,
      employee: null,
      imgViewerVisible: false,
      imgList: []
    };
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer")
  },
  created() {
    console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.info();
    this.Sblb();
    this.Bz();
    // this.Scbg1()
    this.getReport();
  },
  mounted() {
    let height = this.$refs.element.offsetHeight; // 100
  },
  methods: {
    downloadFile() {
      window.location.href = this.reportUrl;
    },
    showImgViewer(url) {
      let urls = [];
      urls.push(url);

      this.imgList = urls;

      this.imgViewerVisible = true;
      const m = e => {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
    closeImgViewer() {
      this.imgViewerVisible = false;
      const m = e => {
        e.preventDefault();
      };
      document.body.style.overflow = "auto";
      document.removeEventListener("touchmove", m, true);
    },

    Todit() {
      this.$router.push("/dt?id=" + this.id);
    },
    dell(id, id1) {
      this.Bnb[id].imageList.splice(id1, 1);
      // splice(index,1)
    },
    Progress(id) {
      console.log(id);
      this.progress = id;
    },
    Index(id) {
      return "步骤" + (id + 1);
    },
    // Scbg(){
    //    this.$router.go(this.reportUrl)
    // },
    // 施工报告生成
    Scbg1() {
      this.isCreated = false;
      this.reportLoading = true;
      this.$newGet("/construction/create/report" + this.id).then(res => {
        if (res.code === 2000) {
          this.reportLoading = false
          this.isCreated = true
          this.reportUrl = res.data.reportUrl
        }else{
          this.$message({
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 施工报告查询
    getReport() {
      this.$newGet("/construction/report/report" + this.id, "get").then(res => {
        this.isCreated = res.data.isCreated;
        this.reportUrl = res.data.url;
      });
    },
    back() {
      this.$router.go(-1); // 返回上一层
    },
    Xbxx(id) {
      this.dialogFormVisible1 = true;
      this.form1 = {
        address: this.Data.address,
        id: id,
        manufactor: this.Data.manufactor,
        name: this.Data.name,
        parameter: this.Data.parameter,
        productionData: this.Data.productionData,
        serialNumber: this.Data.serialNumber,
        specifications: this.Data.specifications,
        voltageLevel: this.Data.voltageLevel
      };
    },
    Xbxx1(id) {
      this.dialogFormVisible2 = true;
      this.form2 = {
        constructionAddress: this.list.constructionAddress,
        constructionDate: this.list.constructionDate,
        endTime: this.list.endTime,
        constructionDirector: this.list.constructionDirector,
        id: id,
        installAddress: this.list.installAddress,
        installInfo: this.list.installInfo,
        projectName: this.list.projectName,
        produceCompany: this.list.produceCompany
      };
    },
    Form() {
      // this.form1.parameter = JSON.stringify(this.newA1)
      this.form1.parameter = null;
      this.$ajax("edsbxx", "post", this.form1).then(res => {
        console.log(res, "edsbxx！！！");
        if (res.code == 2000) {
          this.$message({
            type: "success",
            message: res.message
          });
          this.dialogFormVisible1 = false;
          this.info();
        } else {
          this.$message({
            type: "error",
            message: res.message
          });
        }
      });
    },
    Form1() {
      // console.log(this.form2);
      this.form2.installInfo = JSON.stringify(this.newA);
      this.$ajax("edazxx", "post", this.form2).then(res => {
        console.log(res, "edazxx！！！");
        if (res.code == 2000) {
          this.$message({
            type: "success",
            message: res.message
          });
          this.dialogFormVisible2 = false;
          this.Sblb();
        } else {
          this.$message({
            type: "error",
            message: res.message
          });
        }
      });
    },
    qux1() {
      this.dialogFormVisible1 = false;
    },
    qux2() {
      this.dialogFormVisible2 = false;
    },
    xiug() {
      this.XG = false;
    },
    qux() {
      this.dialogFormVisible = false;
      this.fileList = [];
      this.Tplist = [];
    },
    onchange(file, fileList) {
      let id = {
        num: null
      };

      this.fileList = fileList;
      let fd = new FormData();
      fd.append("file", file.raw); // 传文件

      this.$newPost("/upload/img/" + this.id, fd).then(res => {
        if (res.code == 2000) {
          this.Bnb[this.progress].imageList.push(res.data);

          this.$message({
            type: "success",
            message: res.message
          });
          id = {
            num: res.data.id
          };
          this.Tplist.push(id);
          // this.form.image res.data.id + ''
        } else {
          this.$message({
            type: "error",
            message: res.message
          });
        }
      });
      // this.fileList.push(fileList)
    },
    changeMapImg(file) {
      let fd = new FormData();
      fd.append("file", file.raw); // 传文件

      this.$newPost("/upload/mapImg/" + this.id, fd).then(res => {
        if (res.code == 2000) {
          this.list.mapImg = res.data.link;

          this.$message({
            type: "success",
            message: res.message
          });
        } else {
          this.$message({
            type: "error",
            message: res.message
          });
        }
      });
    },
    Kszp(id) {
      // this.dialogFormVisible = true
      // this.form.id = id
      // this.$refs.upload.clearFiles()
    },
    handleRemove(file, fileList) {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // this.dialogImageUrl.push(file.url);
      this.dialogVisible = true;
    },
    handleDownload(file) {},
    submitUpload() {
      let narr = [];
      // this.Tplist.forEach(item => {
      //   narr.push(item.num)
      // })
      this.Bnb.forEach((item, index) => {
        item.imageList.forEach(item1 => {
          narr.push(item1.id);
        });
        this.Bnb[index].image = narr.join(",");
        narr = [];
      });

      // console.log(narr,'narr111');
      // narr = narr.join(',')
      // console.log(narr, 'narr111');
      this.Up();
    },
    Up() {
      // this.form.image = id
      let _this = this;
      this.Bnb.forEach(item => {
        let obj = {
          id: item.id,
          image: item.image,
          operationProcess: item.operationProcess,
          step: item.step
        };
        _this.$ajax("edsgbz", "post", obj).then(res => {
          // eslint-disable-next-line eqeqeq
          if (res.code == 2000) {
            (_this.form = {
              id: null,
              image: "",
              operationProcess: "",
              step: ""
            }),
              (_this.Tplist = []);
            _this.XG = true;
            _this.dialogFormVisible = false;
            _this.Bz();
          } else {
            _this.$message({
              type: "error",
              message: res.message
            });
          }
        });
      });
    },
    To() {
      this.$router.push("/gjsz_5");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    xian(item) {
      item.qr = !item.qr;
    },
    info() {
      this.$ajax("sgsb", "get", this.id).then(res => {
        this.Data = res.data;
        // let nadd = JSON.parse(res.data.parameter)
        let nadd = res.data.parameter.split(",");
        this.newA1 = nadd;
      });
    },
    Sblb() {
      this.$ajax("sgaz", "get", this.id).then(res => {
        this.list = res.data;
        // this.list.employeeName = this.list.employeeName.replace(this.list.constructionDirector + ',', '')
        // this.list.employeeName = this.list.employeeName.replace(this.list.constructionDirector, '')
        // let nadd = res.data.installInfo.replace(/\"/g, "")
        let nadd = JSON.parse(res.data.installInfo);
        // nadd = nadd.split(",")
        this.newA = nadd;
      });
    },
    Bz() {
      this.$ajax("sgbz", "get", this.id).then(res => {
        res.data.forEach(item => {
          if (item.imageList == null) {
            item.imageList = [];
          }
        });
        this.Bnb = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./tupian.css";

a {
  text-decoration: none;
  color: #ffffff;
}

.img_1 {
  position: relative;
  display: inline-block;
  margin-left: 2rem;
  width: 12.5rem;
  height: 7.5rem;
}

// .img_1 img:hover{
//   transform: scale(5);
//   z-index: 999;
// }
.m1 {
  position: relative;
  color: #ffffff;
  margin: 1rem 0 0 1rem;
  width: 154.6rem;
  // border: 1px solid #00FFFF;
  // overflow: auto;

  // height: 59.4rem;

  .hd {
    position: relative;
    width: 153.6rem;
    height: 5rem;

    .h1 {
      display: inline-block;
      // width: 11.5rem;
      height: 5rem;
      line-height: 5rem;
      padding-left: 1rem;
      font-size: 1.8rem;
      cursor: pointer;
    }

    .h2 {
      position: absolute;
      top: 1rem;
      right: 2rem;
      width: 11.5rem;
      height: 4.2rem;
      // background-color: rgba(43, 201, 222, 1);
      border: 1px solid rgba(43, 201, 222, 1);
      line-height: 4.2rem;
      text-align: center;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }

  .hd1 {
    position: relative;
    width: 153.6rem;
    margin-left: 1rem;
    margin-top: 1rem;
    height: 5rem;
    line-height: 5rem;
    margin-bottom: 1rem;
    color: rgba(43, 201, 222, 1);

    .h1 {
      position: absolute;
      top: 50%;
      right: 28rem;
      transform: translateY(-50%);
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }

    .h2 {
      position: absolute;
      top: 50%;
      right: 3rem;
      transform: translateY(-50%);
      width: 11.5rem;
      height: 4.2rem;
      background-color: rgba(43, 201, 222, 0.4);
      line-height: 4.2rem;
      text-align: center;
      border-radius: 0.5rem;
    }

    .el-button {
      position: absolute;
      top: 50%;
      right: 16rem;
      transform: translateY(-50%);
      border: none;
      border-radius: 0.5rem;
      padding: 0;
      width: 11.5rem;
      height: 4.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: rgba(43, 201, 222, 0.4);
    }
  }

  .hd2 {
    // height: 8.4rem;
    margin-left: 1rem;
    width: 100%;

    .h1 {
      display: inline-block;
      width: 46rem;
      height: 5rem;
      margin-right: 5rem;
    }
  }

  .lb {
    position: relative;
    // border: 1px solid #2BDEC9;
    margin-top: 1rem;
    font-size: 1.4rem;
    // overflow: auto;

    .hd {
      width: 100%;
      height: 3.6rem;
      line-height: 3.6rem;
      // padding-left: 1rem;
      font-size: 1.4rem;

      // border-bottom: 1px solid #2BDEC9;
      .hd_1 {
        display: inline-block;
        width: 4.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        text-align: center;
        margin-right: 0.3%;
        background-color: rgba(43, 201, 222, 0.6);
      }

      .hd_2 {
        display: inline-block;
        width: 94.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        background-color: rgba(43, 201, 222, 0.6);
        margin-right: 0.3%;
      }

      .zh {
        margin-left: 1rem;
        display: inline-block;
        width: 16.8rem;
        overflow: hidden;
      }
    }

    .hd:last-child {
      // border-bottom: none;
    }

    .qx {
      position: absolute;
      top: 0.1rem;
      left: 50rem;
      display: inline-block;

      .lst {
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        background-color: rgba(43, 201, 222, 0.6);
        text-align: center;
        border-radius: 0.3rem;
        margin-right: 1rem;
      }
    }

    .xg {
      position: absolute;
      top: 0.5rem;
      right: 2rem;
      width: 27rem;
      height: 2.6rem;
      line-height: 2.6rem;

      .gg {
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        text-align: center;
        background-color: rgba(28, 184, 184, 1);
        border-radius: 0.4rem;
        margin-left: 1rem;
      }
    }
  }

  .main {
    position: relative;
    width: 100%;
    // height: 4.2rem;
    margin-top: 1rem;

    .m_1 {
      display: inline-block;
      width: 4.5%;
      height: 4.2rem;
      line-height: 4.2rem;
      text-align: center;
      margin-right: 0.3%;
      border: 1px solid rgba(43, 201, 222, 0.6);
      box-sizing: border-box;
    }

    .m_2 {
      display: inline-block;
      width: 94.5%;
      height: 4.2rem;
      line-height: 4.2rem;
      border: 1px solid rgba(43, 201, 222, 0.6);
      box-sizing: border-box;
      margin-right: 0.3%;
    }

    .m_3 {
      position: relative;
      box-sizing: border-box;
      float: right;
      width: 94.5%;
      height: 4.2rem;
      border: 1px solid rgba(43, 201, 222, 0.6);
      box-sizing: border-box;
      margin-top: 0.5rem;
      line-height: 4.2rem;

      .zh {
        margin-left: 1rem;
        display: inline-block;
        width: 16.8rem;
        overflow: hidden;
      }

      .qx {
        position: absolute;
        top: 0.1rem;
        left: 50rem;
        display: inline-block;

        .lst {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          background-color: rgba(43, 201, 222, 0.6);
          text-align: center;
          border-radius: 0.3rem;
          margin-right: 1rem;
        }
      }

      .xg {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
        width: 27rem;
        height: 2.6rem;
        line-height: 2.6rem;

        .gg {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          text-align: center;
          background-color: rgba(28, 184, 184, 1);
          border-radius: 0.4rem;
          margin-left: 1rem;
        }
      }
    }

    .m_3:last-child {
      margin-bottom: 1rem;
    }
  }
}

.map-img-box {
  display: flex;
  margin-left: 2rem;
  width: 15rem;
  min-height: 10rem;
  border: 1px solid white;
  align-items: center;
  justify-content: center;
}
</style>
